import zyLogo from './zy/logo.svg';
import zyDefaultProject from './zy/default-project';

const info = {
    'zy.ecode.cn': {
        home: 'https://www.zy.com/',
        logo: zyLogo,
        defaultProject: zyDefaultProject
    }
};

export default info;
