exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .feedback-modal_modal-content_2DbP6 {\n    width: 880px;\n} .feedback-modal_header_pZhEZ {\n    background-color: hsla(163, 85%, 40%, 1);\n} .feedback-modal_iframeBox_1y6YU {\n    height: 70vh;\n} .feedback-modal_iframe_10Ec4 {\n    display: block;\n    min-width: 100%;\n    width: 100px;\n    border: none;\n    overflow: auto;\n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "feedback-modal_modal-content_2DbP6",
	"modalContent": "feedback-modal_modal-content_2DbP6",
	"header": "feedback-modal_header_pZhEZ",
	"iframeBox": "feedback-modal_iframeBox_1y6YU",
	"iframe": "feedback-modal_iframe_10Ec4"
};