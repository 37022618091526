import PropTypes from 'prop-types';
import React from 'react';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import styles from './feedback-modal.css';


const FeedbackModalComponent = props => (
    <Modal
        className={styles.modalContent}
        contentLabel=""
        headerClassName={styles.header}
        id="feedbackModal"
        onRequestClose={props.onRequestClose}
    >
        <Box className={styles.iframeBox}>
            <iframe
                height="100%"
                scrolling="yes"
                className={styles.iframe}
                frameBorder={0}
                src="https://yljctwmmut73vqy7.mikecrm.com/b24RrJ9"
            />
        </Box>
    </Modal>
);

FeedbackModalComponent.propTypes = {
    onRequestClose: PropTypes.func
};

export default FeedbackModalComponent;
