import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import SubLibraryModalComponent from '../components/sub-library-modal/sub-library-modal.jsx';

const SubLibraryModal = ({data, loadedModules, onRequestClose, onConfirm}) => (
    <SubLibraryModalComponent
        data={data}
        loadedModules={loadedModules}
        onRequestClose={onRequestClose}
        onConfirm={onConfirm}
    />
);

SubLibraryModal.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    loadedModules: PropTypes.instanceOf(Map),
    onRequestClose: PropTypes.func,
    onConfirm: PropTypes.func
};

export default injectIntl(SubLibraryModal);
