import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';

import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';

import makeymakeyIconURL from './makeymakey/makeymakey.png';
import makeymakeyInsetIconURL from './makeymakey/makeymakey-small.svg';

import microbitIconURL from './microbit/microbit.png';
import microbitInsetIconURL from './microbit/microbit-small.svg';
import microbitConnectionIconURL from './microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';

import nezhaIconURL from './nezha/nezha.jpg';
import nezhaInsetIconURL from './nezha/nezha-small.svg';

import planetXIconURL from './planetx/planetx.png';
import planetXInsetIconURL from './planetx/planetx-led.png';

import planetXLedIconURL from './planetx/led.png';
import planetXNeoPixel8IconURL from './planetx/neo-pixel-8.png';
import planetXOledIconURL from './planetx/oled.png';
import planetXMatrix1608IconURL from './planetx/matrix1608.png';
import planetXLightIconURL from './planetx/light.png';
import planetXNoiseIconURL from './planetx/noise.png';
import planetXUltrasonicIconURL from './planetx/ultrasonic.png';
import planetXDHT11IconURL from './planetx/dht11.png';
import planetXWaterLevelIconURL from './planetx/water-level.png';
import planetXSoilMoistureIconURL from './planetx/soil-moisture.png';
import planetXDustIconURL from './planetx/dust.png';
import planetXPM25IconURL from './planetx/pm2.5.png';
import planetXUVIconURL from './planetx/uv.png';
import planetXBME280IconURL from './planetx/BME280.png';
import planetXButtonIconURL from './planetx/button.png';
import planetXCrashIconURL from './planetx/crash.png';
import planetXPirIconURL from './planetx/pir.png';
import planetXTrimpotIconURL from './planetx/trimpot.png';
import planetXGestureIconURL from './planetx/gesture.png';
import planetXASRIconURL from './planetx/asr.png';
import planetXColorIconURL from './planetx/color.png';
import planetXTrackingIconURL from './planetx/tracking.png';
import planetXMotorFanIconURL from './planetx/motor-fan.png';
import planetXLaserIconURL from './planetx/laser.png';
import planetXAILensIconURL from './planetx/ai-lens.png';
import planetXMP3IconURL from './planetx/mp3.png';
import planetXSegmentIconURL from './planetx/segment.png';

import microbitSerialInsetIconURL from './microbit-serial/microbit-small.svg';
import microbitSerialConnectionSmallIconURL from './microbit-serial/microbit-small.svg';

import octopusIconURL from './octopus/octopus.png';
import octopusInsetIconURL from './octopus/octopus-small.png';
import octopusServoIconURL from './octopus/servo.png';
import octopusNeopixelIconURL from './octopus/neopixel.png';

import fidgetSpinnerIconURL from './fidget-spinner/fidget-spinner.png';
import fidgetSpinnerInsetIconURL from './fidget-spinner/fidget-spinner-small.png';
import fidgetSpinnerConnectionIconURL from './fidget-spinner/fidget-spinner-illustration.png';
import fidgetSpinnerConnectionSmallIconURL from './fidget-spinner/fidget-spinner-small.png';

import ev3IconURL from './ev3/ev3.png';
import ev3InsetIconURL from './ev3/ev3-small.svg';
import ev3ConnectionIconURL from './ev3/ev3-hub-illustration.svg';
import ev3ConnectionSmallIconURL from './ev3/ev3-small.svg';

import wedo2IconURL from './wedo2/wedo.png'; // TODO: Rename file names to match variable/prop names?
import wedo2InsetIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionIconURL from './wedo2/wedo-illustration.svg';
import wedo2ConnectionSmallIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionTipIconURL from './wedo2/wedo-button-illustration.svg';

import boostIconURL from './boost/boost.png';
import boostInsetIconURL from './boost/boost-small.svg';
import boostConnectionIconURL from './boost/boost-illustration.svg';
import boostConnectionSmallIconURL from './boost/boost-small.svg';
import boostConnectionTipIconURL from './boost/boost-button-illustration.svg';

import gdxforIconURL from './gdxfor/gdxfor.png';
import gdxforInsetIconURL from './gdxfor/gdxfor-small.svg';
import gdxforConnectionIconURL from './gdxfor/gdxfor-illustration.svg';
import gdxforConnectionSmallIconURL from './gdxfor/gdxfor-small.svg';

export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Text to Speech"
                description="Name for the Text to Speech extension"
                id="gui.extension.text2speech.name"
            />
        ),
        extensionId: 'text2speech',
        iconURL: text2speechIconURL,
        insetIconURL: text2speechInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make your projects talk."
                description="Description for the Text to speech extension"
                id="gui.extension.text2speech.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Translate"
                description="Name for the Translate extension"
                id="gui.extension.translate.name"
            />
        ),
        extensionId: 'translate',
        collaborator: 'Google',
        iconURL: translateIconURL,
        insetIconURL: translateInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Translate text into many languages."
                description="Description for the Translate extension"
                id="gui.extension.translate.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: 'Makey Makey',
        extensionId: 'makeymakey',
        collaborator: 'JoyLabz',
        iconURL: makeymakeyIconURL,
        insetIconURL: makeymakeyInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything into a key."
                description="Description for the 'Makey Makey' extension"
                id="gui.extension.makeymakey.description"
            />
        ),
        featured: true
    },
    {
        name: 'micro:bit',
        extensionId: 'microbit0x0microbit',
        collaborator: 'ELECFREAKS',
        iconURL: microbitIconURL,
        insetIconURL: microbitInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://www.yuque.com/elecfreaks-learn/xrknfm/aud5en#o6FCW'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Nezha"
                description="Name for the Nezha extension"
                id="gui.extension.nezha.name"
            />
        ),
        extensionId: 'microbit0x0nezha',
        collaborator: 'ELECFREAKS',
        iconURL: nezhaIconURL,
        insetIconURL: nezhaInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://www.yuque.com/elecfreaks-learn/xrknfm/aud5en#o6FCW'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="PlanetX"
                description="Name for the PlanetX extension"
                id="gui.extension.planetx.name"
            />
        ),
        extensionId: 'microbit0x0planetx',
        collaborator: 'ELECFREAKS',
        iconURL: planetXIconURL,
        insetIconURL: planetXInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        hasSubLibrary: true,
        subLibrary: [
            {
                name: (
                    <FormattedMessage
                        defaultMessage="Lighting and Display"
                        description="Lighting and Display"
                        id="gui.extension.planetx.lightingAndDisplay"
                    />
                ),
                modules: [
                    {
                        id: 'led',
                        opcodes: ['setLEDBrightness'],
                        description: (
                            <FormattedMessage
                                defaultMessage="LED"
                                description="planetx led"
                                id="gui.extension.planetx.led"
                            />
                        ),
                        iconURL: planetXLedIconURL
                    },
                    {
                        id: 'pixel',
                        opcodes: [
                            'neopixelSetLength',
                            'neopixelShowRainbow',
                            'neopixelShowColor',
                            'neopixelShowBarGraph',
                            'neopixelClear',
                            'neopixelShow',
                            'neopixelShift',
                            'neopixelRotate',
                            'neopixelSetPixelColor',
                            'getColorFromRGB',
                            'getColorFromHSB',
                            'neopixelSetBrightness'
                        ],
                        description: (
                            <FormattedMessage
                                defaultMessage="NeoPixel"
                                description="planetx neo pixel"
                                id="gui.extension.planetx.neoPixel"
                            />
                        ),
                        iconURL: planetXNeoPixel8IconURL
                    },
                    {
                        id: 'oled',
                        opcodes: ['oledInit', 'oledDisplayText', 'oledClear'],
                        description: (
                            <FormattedMessage
                                defaultMessage="OLED"
                                description="planetx oled"
                                id="gui.extension.planetx.oled"
                            />
                        ),
                        iconURL: planetXOledIconURL
                    },
                    {
                        id: 'matrix8x16',
                        opcodes: [
                            'matrix8x16Init',
                            'matrix8x16DisplaySymbol',
                            'matrix8x16Plot',
                            'matrix8x16Toggle',
                            'matrix8x16Unplot',
                            'matrix8x16Show',
                            'matrix8x16Clear',
                            'matrix8x16Point'
                        ],
                        description: (
                            <FormattedMessage
                                defaultMessage="8x16 Matrix"
                                description="8x16 matrix"
                                id="gui.extension.planetx.matrix8x16"
                            />
                        ),
                        iconURL: planetXMatrix1608IconURL
                    },
                    {
                        id: 'segment',
                        opcodes: [
                            'segmentSetBrightness',
                            'segmentShowInteger',
                            'segmentShowNumber',
                            'segmentSetDigit',
                            'segmentSetDot',
                            'segmentSetSegment',
                            'segmentShow',
                            'segmentClear'
                        ],
                        description: (
                            <FormattedMessage
                                defaultMessage="Segment"
                                description="Segment"
                                id="gui.extension.planetx.segment"
                            />
                        ),
                        iconURL: planetXSegmentIconURL
                    }
                ]
            },
            {
                name: (
                    <FormattedMessage
                        defaultMessage="Environmental Monitoring"
                        description="Environmental Monitoring"
                        id="gui.extension.planetx.environmentalMonitoring"
                    />
                ),
                modules: [
                    {
                        id: 'light',
                        opcodes: ['getLightValue'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Light Sensor"
                                description="planetx light sensor"
                                id="gui.extension.planetx.lightSensor"
                            />
                        ),
                        iconURL: planetXLightIconURL
                    },
                    {
                        id: 'noise',
                        opcodes: ['getNoiseValue'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Noise Sensor"
                                description="planetx noise sensor"
                                id="gui.extension.planetx.noiseSensor"
                            />
                        ),
                        iconURL: planetXNoiseIconURL
                    },
                    {
                        id: 'dht11',
                        opcodes: ['getDHT11'],
                        description: (
                            <FormattedMessage
                                defaultMessage="DHT11 Sensor"
                                description="planetx dht11 sensor"
                                id="gui.extension.planetx.dht11Sensor"
                            />
                        ),
                        iconURL: planetXDHT11IconURL
                    },
                    {
                        id: 'ultrasonic',
                        opcodes: ['getUltrasonicValue'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Ultrasonic Sensor"
                                description="planetx ultrasonic sensor"
                                id="gui.extension.planetx.ultrasonicSensor"
                            />
                        ),
                        iconURL: planetXUltrasonicIconURL
                    },
                    {
                        id: 'water_level',
                        opcodes: ['getWaterLevelValue'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Water Level Sensor"
                                description="planetx water level sensor"
                                id="gui.extension.planetx.waterLevelSensor"
                            />
                        ),
                        iconURL: planetXWaterLevelIconURL
                    },
                    {
                        id: 'soil_moisture',
                        opcodes: ['getSoilHumidityValue'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Soil Moisture Sensor"
                                description="planetx soil moisture sensor"
                                id="gui.extension.planetx.soilMoistureSensor"
                            />
                        ),
                        iconURL: planetXSoilMoistureIconURL
                    },
                    {
                        id: 'pm25',
                        opcodes: ['getPM25Value'],
                        description: (
                            <FormattedMessage
                                defaultMessage="PM2.5 Sensor"
                                description="planetx pm2.5 sensor"
                                id="gui.extension.planetx.pm25Sensor"
                            />
                        ),
                        iconURL: planetXPM25IconURL
                    },
                    {
                        id: 'dust',
                        opcodes: ['getDustValue'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Dust Sensor"
                                description="planetx dust sensor"
                                id="gui.extension.planetx.dustSensor"
                            />
                        ),
                        iconURL: planetXDustIconURL
                    },
                    {
                        id: 'uv',
                        opcodes: ['getUVValue'],
                        description: (
                            <FormattedMessage
                                defaultMessage="UV Sensor"
                                description="planetx UV sensor"
                                id="gui.extension.planetx.UVSensor"
                            />
                        ),
                        iconURL: planetXUVIconURL
                    },
                    {
                        id: 'bme280',
                        opcodes: ['bme280Init', 'getBME280'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Environment Sensor"
                                description="planetx environment sensor"
                                id="gui.extension.planetx.environmentSensor"
                            />
                        ),
                        iconURL: planetXBME280IconURL
                    }
                ]
            },
            {
                name: (
                    <FormattedMessage
                        defaultMessage="Interactive"
                        description="Interactive"
                        id="gui.extension.planetx.interactive"
                    />
                ),
                modules: [
                    {
                        id: 'button',
                        opcodes: ['whenButtonCDPressed', 'isButtonCDPressed'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Button"
                                description="planetx button"
                                id="gui.extension.planetx.button"
                            />
                        ),
                        iconURL: planetXButtonIconURL
                    },
                    {
                        id: 'crash',
                        opcodes: ['whenCrashSensorPressed', 'isCrashSensorPressed'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Crash Sensor"
                                description="planetx crash sensor"
                                id="gui.extension.planetx.crashSensor"
                            />
                        ),
                        iconURL: planetXCrashIconURL
                    },
                    {
                        id: 'pir',
                        opcodes: ['whenPIRDetectsMotion', 'isPIRDetectsMotion'],
                        description: (
                            <FormattedMessage
                                defaultMessage="PIR Sensor"
                                description="planetx PIR sensor"
                                id="gui.extension.planetx.PIRSensor"
                            />
                        ),
                        iconURL: planetXPirIconURL
                    },
                    {
                        id: 'trimpot',
                        opcodes: ['getPotentiometerValue'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Trimpot"
                                description="planetx trimpot"
                                id="gui.extension.planetx.trimpotSensor"
                            />
                        ),
                        iconURL: planetXTrimpotIconURL
                    },
                    {
                        id: 'gesture',
                        opcodes: ['gestureSensorInit', 'whenGestureSensorDetectGesture'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Gesture Sensor"
                                description="planetx gesture sensor"
                                id="gui.extension.planetx.gestureSensor"
                            />
                        ),
                        iconURL: planetXGestureIconURL
                    },
                    {
                        id: 'asr',
                        opcodes: ['ASRInit', 'ASRSensorFactoryReset', 'ASRSensorLearn', 'whenASRSensorHeard'],
                        description: (
                            <FormattedMessage
                                defaultMessage="ASR Sensor"
                                description="planetx asr sensor"
                                id="gui.extension.planetx.asrSensor"
                            />
                        ),
                        iconURL: planetXASRIconURL
                    },
                    {
                        id: 'color',
                        opcodes: ['colorSensorInit', 'colorSensorDetectColor', 'colorSensorGetColor'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Color Sensor"
                                description="planetx color sensor"
                                id="gui.extension.planetx.colorSensor"
                            />
                        ),
                        iconURL: planetXColorIconURL
                    }
                ]
            },
            {
                name: (
                    <FormattedMessage
                        defaultMessage="Others"
                        description="Others"
                        id="gui.extension.planetx.others"
                    />
                ),
                modules: [
                    {
                        id: 'tracking',
                        opcodes: ['isLineTrackingTracked'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Tracking Sensor"
                                description="planetx tracking sensor"
                                id="gui.extension.planetx.trackingSensor"
                            />
                        ),
                        iconURL: planetXTrackingIconURL
                    },
                    {
                        id: 'motor_fan',
                        opcodes: ['setFanSpeed'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Motor Fan"
                                description="planetx motor fan"
                                id="gui.extension.planetx.motorFan"
                            />
                        ),
                        iconURL: planetXMotorFanIconURL
                    },
                    {
                        id: 'laser',
                        opcodes: ['toggleLaser'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Laser"
                                description="planetx laser"
                                id="gui.extension.planetx.laser"
                            />
                        ),
                        iconURL: planetXLaserIconURL
                    },
                    {
                        id: 'ai_lens',
                        opcodes: [
                            'switchAILensFunction',
                            'aiLensGetAFrame',
                            'aiLensRecognize',
                            'numberCard',
                            'letterCard',
                            'trafficCard',
                            'otherCard',
                            'getColorCard',
                            'getFace',
                            'getBall',
                            'aiLensGetTotal',
                            'aiLensGetInfo',
                            'aiLensGetLineInfo',
                            'aiLensRecognizedLineDirection',
                            'aiLensLearnObject',
                            'clearLearnedObjects',
                            'aiLensRecognizedLearned',
                            'aiLensGetLearnedObjectConfidence'
                        ],
                        description: (
                            <FormattedMessage
                                defaultMessage="AI Lens"
                                description="planetx ai lens"
                                id="gui.extension.planetx.aiLens"
                            />
                        ),
                        iconURL: planetXAILensIconURL
                    },
                    {
                        id: 'mp3',
                        opcodes: [
                            'setMP3Port',
                            'mp3LoopFolder',
                            'mp3Play',
                            'controlMP3',
                            'setMP3Volume'
                        ],
                        description: (
                            <FormattedMessage
                                defaultMessage="MP3"
                                description="planetx MP3"
                                id="gui.extension.planetx.MP3"
                            />
                        ),
                        iconURL: planetXMP3IconURL
                    }
                ]
            }
        ],
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://www.yuque.com/elecfreaks-learn/xrknfm/aud5en#o6FCW'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="micro:bit [Serial]"
                description="micro:bit serial"
                id="gui.extension.microbitSerial.name"
            />
        ),
        extensionId: 'microbitserial0x0microbit',
        collaborator: 'ELECFREAKS',
        iconURL: microbitIconURL,
        insetIconURL: microbitSerialInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        serialportRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitSerialConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://www.yuque.com/elecfreaks-learn/xrknfm/aud5en#o6FCW'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Octopus [Serial]"
                description="octopus serial"
                id="gui.extension.microbitOctopusSerial.name"
            />
        ),
        extensionId: 'microbitserial0x0octopus',
        collaborator: 'ELECFREAKS',
        iconURL: octopusIconURL,
        insetIconURL: octopusInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        serialportRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        hasSubLibrary: true,
        subLibrary: [
            {
                name: (
                    <FormattedMessage
                        defaultMessage="Lighting and Display"
                        description="Lighting and Display"
                        id="gui.extension.planetx.lightingAndDisplay"
                    />
                ),
                modules: [
                    {
                        id: 'neopixel',
                        opcodes: [
                            'setNeoPixelLength',
                            'setNeoPixelColor',
                            'NeoPixelClear',
                            'NeoPixelShow',
                            'getColorFromRGB'
                        ],
                        description: (
                            <FormattedMessage
                                defaultMessage="NeoPixel"
                                description="planetx neo pixel"
                                id="gui.extension.planetx.neoPixel"
                            />
                        ),
                        iconURL: octopusNeopixelIconURL
                    }
                ]
            },
            {
                name: (
                    <FormattedMessage
                        defaultMessage="Others"
                        description="Others"
                        id="gui.extension.planetx.others"
                    />
                ),
                modules: [
                    {
                        id: 'continuous_servo',
                        opcodes: ['continuousServoSpeed', 'stopContinuousServo'],
                        description: (
                            <FormattedMessage
                                defaultMessage="Continuous Servo"
                                description=""
                                id="gui.extension.octopus.continuousServo"
                            />
                        ),
                        iconURL: octopusServoIconURL
                    }
                ]
            }
        ],
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitSerialConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://www.yuque.com/elecfreaks-learn/xrknfm/aud5en#o6FCW'
    },
    {
        name: 'Fidget Spinner',
        extensionId: 'fidgetspinner',
        collaborator: 'ELECFREAKS',
        iconURL: fidgetSpinnerIconURL,
        insetIconURL: fidgetSpinnerInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the fidget spinner."
                description="Description for the 'fidgetspinner' extension"
                id="gui.extension.fidgetspinner.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: fidgetSpinnerConnectionIconURL,
        connectionSmallIconURL: fidgetSpinnerConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://www.yuque.com/elecfreaks-learn/xrknfm/aud5en#o6FCW'
    },
    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        collaborator: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more."
                description="Description for the 'LEGO MINDSTORMS EV3' extension"
                id="gui.extension.ev3.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
                description="Message to help people connect to their EV3. Must note the PIN should be 1234."
                id="gui.extension.ev3.connectingMessage"
            />
        ),
        helpLink: 'https://creative.ecode.cn/ev3'
    },
    {
        name: 'LEGO BOOST',
        extensionId: 'boost',
        collaborator: 'LEGO',
        iconURL: boostIconURL,
        insetIconURL: boostInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Bring robotic creations to life."
                description="Description for the 'LEGO BOOST' extension"
                id="gui.extension.boost.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: boostConnectionIconURL,
        connectionSmallIconURL: boostConnectionSmallIconURL,
        connectionTipIconURL: boostConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their BOOST."
                id="gui.extension.boost.connectingMessage"
            />
        ),
        helpLink: 'https://creative.ecode.cn/boost'
    },
    {
        name: 'LEGO Education WeDo 2.0',
        extensionId: 'wedo2',
        collaborator: 'LEGO',
        iconURL: wedo2IconURL,
        insetIconURL: wedo2InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build with motors and sensors."
                description="Description for the 'LEGO WeDo 2.0' extension"
                id="gui.extension.wedo2.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: wedo2ConnectionIconURL,
        connectionSmallIconURL: wedo2ConnectionSmallIconURL,
        connectionTipIconURL: wedo2ConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their WeDo."
                id="gui.extension.wedo2.connectingMessage"
            />
        ),
        helpLink: 'https://creative.ecode.cn/wedo'
    },
    {
        name: 'Go Direct Force & Acceleration',
        extensionId: 'gdxfor',
        collaborator: 'Vernier',
        iconURL: gdxforIconURL,
        insetIconURL: gdxforInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense push, pull, motion, and spin."
                description="Description for the Vernier Go Direct Force and Acceleration sensor extension"
                id="gui.extension.gdxfor.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: gdxforConnectionIconURL,
        connectionSmallIconURL: gdxforConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their force and acceleration sensor."
                id="gui.extension.gdxfor.connectingMessage"
            />
        ),
        helpLink: 'https://creative.ecode.cn/vernier'
    }
];
