exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .check-box_checkbox_pe3us {\n    display: none;\n} .check-box_checkbox_pe3us:checked+label {\n    background: hsla(163, 85%, 40%, 1);\n} .check-box_checkbox_pe3us:checked+label::before {\n    opacity: 1;\n    -webkit-transform: all 0.5s;\n        -ms-transform: all 0.5s;\n            transform: all 0.5s;\n} .check-box_label-checkbox_Z2yFk {\n    display: inline-block;\n    width: 26px;\n    height: 26px;\n    border-radius: 50%;\n    border: 2px solid hsla(163, 85%, 40%, 1);\n    position: relative;\n    cursor: pointer;\n} .check-box_label-checkbox_Z2yFk.check-box_disable_2HKkK {\n    opacity: 0.4;\n} .check-box_label-checkbox_Z2yFk::before {\n    display: inline-block;\n    content: \" \";\n    width: 14px;\n    border: 3px solid hsla(0, 100%, 100%, 1);\n    height: 6px;\n    border-top: none;\n    border-right: none;\n    -webkit-transform: rotate(-45deg);\n        -ms-transform: rotate(-45deg);\n            transform: rotate(-45deg);\n    top: 5px;\n    left: 3px;\n    position: absolute;\n    opacity: 0;\n}\n", ""]);

// exports
exports.locals = {
	"checkbox": "check-box_checkbox_pe3us",
	"label-checkbox": "check-box_label-checkbox_Z2yFk",
	"labelCheckbox": "check-box_label-checkbox_Z2yFk",
	"disable": "check-box_disable_2HKkK"
};