import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './check-box.css';

const CheckBox = function ({id, checked, disable, onChange}) {
    return (
        <div>
            <input
                className={styles.checkbox}
                type="checkbox"
                id={id}
                checked={checked}
                disabled={disable}
                onChange={onChange}
            />
            <label
                className={classNames(styles.labelCheckbox, {[styles.disable]: disable})}
                htmlFor={id}
            />
        </div>
    );
};

CheckBox.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    disable: PropTypes.bool,
    onChange: PropTypes.func
};

export default CheckBox;
