import React from 'react';
import {defineMessages, injectIntl, FormattedMessage, intlShape} from 'react-intl';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import Modal from '../../containers/modal.jsx';
import Box from '../box/box.jsx';
import CheckBox from '../check-box/check-box.jsx';
import classNames from 'classnames';

import styles from './sub-library-modal.css';

import okIcon from './icon-ok.svg';

const messages = defineMessages({
    subLibraryTitle: {
        defaultMessage: 'Choose the Necessary Modules',
        description: 'Heading for the extension sub library',
        id: 'gui.extensionSubLibrary.ChooseTheNecessaryModules'
    }
});

class SubLibraryModalComponent extends React.Component {
    constructor (props) {
        super(props);

        const checked = new Map();
        props.loadedModules.forEach((_opcodes, id) => checked.set(id, true));
        this.state = {
            modulesChecked: checked
        };

        bindAll(this, [
            'handleClose',
            'handleCheckBoxChange',
            'handleOkClick'
        ]);
    }
    handleClose () {
        this.props.onRequestClose();
    }
    handleCheckBoxChange ({target}) {
        this.setState({
            modulesChecked: this.state.modulesChecked.set(target.id, target.checked)
        });
    }
    handleOkClick () {
        const checkedModuleIds = [];
        const checkedModules = new Map();
        this.state.modulesChecked.forEach((checked, id) => {
            if (checked) {
                checkedModuleIds.push(id);
            }
        });
        this.props.data.forEach(item => {
            item.modules.forEach(module => {
                if (checkedModuleIds.includes(module.id)) {
                    checkedModules.set(module.id, module.opcodes);
                }
            });
        });
        this.props.onConfirm(checkedModules);
    }
    render () {
        return (
            <Modal
                className={styles.modalContent}
                tabIndex="0"
                headerClassName={styles.header}
                contentLabel={this.props.intl.formatMessage(messages.subLibraryTitle)}
                id="libraryItemModulesModal"
                onRequestClose={this.handleClose}
            >
                <Box
                    className={styles.body}
                >
                    <Box className={styles.content}>
                        {this.props.data && this.props.data.map((item, index) => (
                            <Box key={index}>
                                <h3 className={styles.modulesName}>{item.name}</h3>
                                <ul className={styles.modulesUl}>
                                    {item.modules.map((module, moduleIndex) => (
                                        <li
                                            key={moduleIndex}
                                            className={classNames(
                                                styles.moduleLi,
                                                {
                                                    [styles.disable]: this.props.loadedModules.has(module.id)
                                                })
                                            }
                                        >
                                            <label htmlFor={module.id}>
                                                <div className={styles.moduleIconContainer}>
                                                    <img
                                                        src={module.iconURL}
                                                        alt={module.id}
                                                        className={styles.moduleIcon}
                                                    />
                                                </div>
                                                <div className={styles.moduleDescription}>{module.description}</div>
                                            </label>
                                            <div className={styles.checkBoxContainer}>
                                                <CheckBox
                                                    id={module.id}
                                                    checked={this.state.modulesChecked.get(module.id) || false}
                                                    disable={this.props.loadedModules.has(module.id)}
                                                    onChange={this.handleCheckBoxChange}
                                                />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        ))}
                    </Box>
                    <Box className={styles.footer}>
                        <button
                            className={styles.submitButton}
                            onClick={this.handleOkClick}
                        >
                            <img
                                className={styles.buttonIconLeft}
                                src={okIcon}
                            />
                            <FormattedMessage
                                defaultMessage="OK"
                                description="Button in modal for confirming the select"
                                id="gui.prompt.ok"
                            />
                        </button>
                    </Box>
                </Box>
            </Modal>
        );
    }
}

SubLibraryModalComponent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    loadedModules: PropTypes.instanceOf(Map),
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    onConfirm: PropTypes.func
};

export default injectIntl(SubLibraryModalComponent);
